export default {
  signin: 'Logga in',
  email: 'Email',
  password: 'Lösenord',
  forgot_password: 'Glömt lösenord',
  create_account: 'Skapa konto',
  pay_with_klarna: 'Betala med Stripe',
  loading: 'Laddar...',
  forgot_confirm: 'Instruktioner för återställning är skickat till din email.',
  forgot_error: 'Det gick inte att återställa lösenordet på den angivna emailen. Kontrollera och prova igen.',
  name: 'Namn',
  repeat_password: 'Upprepa lösenord',
  create_error_missmatch: 'Lösenorden matchar inte',
  create_error_missing_name: 'Skriv in ditt namn',
  create_error_email_in_use: 'Email addressen används redan.',
  create_error_invalid_email: 'Email addressen är inte giltig',
  create_error_weak_password: 'Lösenordet är för kort. Minst 6 tecken.',
  payment_complete: 'Köp färdigt!',
  payment_finishing: 'Slutför köp',
  back_to_app: 'Gå tillbaka till appen',
  payment_failed: 'Köpet misslyckades',
  my_account: 'Mitt konto',
  have_payed: 'Har betalat',
  pay: 'Betala',
  yes: 'Ja',
  no: 'Nej',
  signin_fail: 'Email och lösenord matchar inte. Kontrollera att du skrivit rätt',

  purchase_account: 'Köp av konto för appen Drop a Story.',
  pay_100: 'Betala 100 kr',
  create_account_intro: 'För att få använda alla funktionerna i Drop a Story appen så måste du skapa ett konto samt betala 100kr. När du har skapat ditt konto kan du betala med Stripe',

  delete_account: 'Radera konto',
  delete_account_warning: 'Är du säker på att du vill radera ditt konto? Denna åtgärd går inte att ångra.',
};

//    {{$t('signin')}}
//    $t('signin')
//    this.$t('create_error_missmatch');
