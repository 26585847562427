import Vue from 'vue';
import Vuex from 'vuex';
import {
  addDoc,
  doc, getDoc, getFirestore, setDoc,
} from 'firebase/firestore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    password: '',
    email: '',
    loaded: false,
    userID: '',
  },
  mutations: {
    setEmail(state, email) {
      state.email = email;
    },
    setPassword(state, password) {
      state.password = password;
    },
    setUserID(state, userID) {
      state.userID = userID;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
  },
  actions: {
    async setUser({ commit }, user) {
      // fetch userID
      console.log('user', user);


      // console.log('user: ', user);

      if (!user || !user.uid) {
        // console.log('user null: ', user);
        commit('setUserID', '');
        commit('setLoaded', true);
        return;
      }

      let userID = '';
      const firestore = getFirestore();

      const accountSnap = await getDoc(doc(firestore, 'accounts', user.uid));

      if (accountSnap.exists()) {
        // the link exists, find the user id.
        userID = accountSnap.data().user;
      } else {
        // the snapshot does not exists. Check if the user exists in /users
        const usersSnap = await getDoc(doc(firestore, 'users', user.uid));
        if (usersSnap.exists) {
          // the users exists in /users but not /accounts. Add to accounts
          // await firebase.firestore().doc(`accounts/${user.uid}`).set({ user: user.uid });
          await setDoc(doc(firestore, 'accounts', user.uid), { user: user.uid });
          userID = user.uid;
        } else {
          // the user does not exists. Add it.
          // const newDoc = await firebase.firestore().collection('users').add({ hasPayed: false });
          // await firebase.firestore().doc(`accounts/${user.uid}`).set({ user: newDoc.id });
          const newDoc = await addDoc(firestore.collection('users'), { hasPayed: false });
          await setDoc(doc(firestore, 'accounts', user.uid), { user: newDoc.id });
          userID = newDoc.id;
        }
      }
      // return userID;

      // const userID = 'as';

      // console.log('user: ', userID);


      commit('setUserID', userID);
      commit('setLoaded', true);
    },
    // checkUserStatus({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     firebase.auth().onAuthStateChanged((user) => {
    //       if (user) {
    //         // commit('SET_USER_ID', user.uid);
    //         // fetch userID

    //         commit('setUserID', userID);
    //         resolve(user.uid);
    //       } else {
    //         reject(Error('User is not logged in.'));
    //       }
    //     });
    //   });
    // },
  },
  modules: {
  },
});
