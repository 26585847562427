<template>
  <div class="form">
    <img src="../assets/logo.png" class="logo" />
    <h1>{{$t('create_account')}}</h1>
    <p>{{$t('create_account_intro')}}</p>
    <input type="text" :placeholder="$t('name')" v-model="name" />
    <input type="text" :placeholder="$t('email')" v-model="email" />
    <input type="password" :placeholder="$t('password')" v-model="password" />
    <input type="password" :placeholder="$t('repeat_password')" v-model="repeatPassword" />
    <div v-if="loading" class="lds-dual-ring"></div>
    <p class="error_message">{{error}}</p>
    <button @click="createAccount">{{$t('create_account')}}</button>
  </div>
</template>

<script>

import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';

export default {
  name: 'create_account',
  data() {
    return {
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      uid: '',
      loading: false,
      error: '',
    };
  },
  mounted() {
    // this.$route.query.test
    // console.log(this.$route.query);
    if (this.$route.query && this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    }
  },
  methods: {
    createAccount() {
      if (this.loading) {
        return;
      }

      if (this.password !== this.repeatPassword) {
        this.error = this.$t('create_error_missmatch');
        return;
      }
      if (this.name === '') {
        this.error = this.$t('create_error_missmatch');
      }

      this.loading = true;
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((user) => {
          console.log('user', user);
          // commit changes to react native if present
          if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: 'password', password: this.password, email: this.email }),
            );
          }
          updateProfile(user.user, {
            displayName: this.name,
          }).then(() => {
            console.log('user', user);
            // navigate to payment
            this.$store.commit('setEmail', this.email);
            this.$store.commit('setPassword', this.password);
            this.$router.push('payment');
            this.loading = false;
          });
        }).catch((e) => {
          console.log('error', e);
          if (e && e.code === 'auth/email-already-in-use') {
            this.error = this.$t('create_error_email_in_use');
          } else if (e && e.code === 'auth/invalid-email') {
            this.error = this.$t('create_error_invalid_email');
          } else if (e && e.code === 'auth/weak-password') {
            this.error = this.$t('create_error_weak_password');
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>

.error_message
  color: red

p
  padding: 0px 10px
</style>
