<template>
  <div class="form">
    <img src="../assets/logo.png" class="logo" />
    <h1>{{ $t('payment_complete') }}</h1>
    <h3>{{ error }}</h3>
    <button @click="backToApp">{{ $t('back_to_app') }}</button>
    <!-- <button @click="checkPaymentSatus">Kolla status</button> -->
    <!-- <p>{{status}}</p> -->
    <!-- <iframe v-html="htmlSnippet">

    </iframe>
    <iframe :src="htmlSnippet">

    </iframe> -->
    <!-- <vue-friendly-iframe :src="htmlSnippet"></vue-friendly-iframe> -->
    <!-- <div v-html="htmlSnippet">

    </div> -->
    <!-- <div id="my-checkout-container" style="width: 50%;"></div> -->
  </div>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth';
// import axios from 'axios';
// import VueFriendlyIframe from 'vue-friendly-iframe';

export default {
  name: 'payment',
  components: {
    // VueFriendlyIframe,
  },
  data() {
    return {
      email: '',
      password: '',
      htmlSnippet: '',
      status: '',
      error: '',
    };
  },
  computed: {
    orderId() {
      // console.log(this.$route.query);

      if (this.$route.query.klarna_order_id) {
        return this.$route.query.klarna_order_id;
      }
      return '';
    },
  },
  async mounted() {
    // this.checkPaymentSatus();

    const auth = getAuth();
    signOut(auth);

    this.$router.push({ path: '/account' });
  },
  methods: {
    backToApp() {
      window.location = 'dropAStory://';
    },
  },
};
</script>

<style lang="sass" scoped>
.sign_in
  margin-top: 40px
  flex-direction: column
  display: flex
  align-items: center

  input
    margin: 10px 0
    width: 20%
    padding: 15px

  button
    margin-top: 20px
    width: 10%
    cursor: pointer
</style>
