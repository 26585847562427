<template>
    <div class="form">
      <h1>{{$t('delete_account')}}</h1>
      <p>{{$t('email')}}: {{user ? user.email : ""}}</p>
      <button @click="deleteAccount">{{$t('delete_account')}}</button>

    </div>
  </template>

<script>

import { getAuth } from 'firebase/auth';

export default {
  name: 'delete',
  data() {
    return {
      user: {},
    };
  },
  created() {
    const auth = getAuth();
    this.user = auth.currentUser;


    if (this.$store.state.userID === '') {
      this.$router.push({ path: '/', query: { redirect: 'delete' } });
    }
  },
  methods: {
    deleteAccount() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm(this.$t('delete_account_warning'))) {
        if (this.user) {
          this.user.delete().then(() => {
            this.$router.push({ path: '/' });
          }).catch((error) => {
            if (error.code === 'auth/requires-recent-login') {
              this.$router.push({ path: '/', query: { redirect: 'delete' } });
            }
            console.log(error);
          });
        }
      }
    },
  },
};
</script>

  <style lang="sass" scoped>
  .delete
    margin-top: 40px
    flex-direction: column
    display: flex
    align-items: center

    button
      margin-top: 20px
      width: 10%
      cursor: pointer

  </style>
