<template>
  <div class="form">
    <h1>{{ $t('my_account') }}</h1>
    <p>{{ $t('name') }}: {{ user ? user.displayName : '' }}</p>
    <p>{{ $t('email') }}: {{ user ? user.email : '' }}</p>
    <p>{{ $t('have_payed') }}: {{ hasPayed ? $t('yes') : $t('no') }}</p>
    <button @click="pay" v-if="!hasPayed">{{ $t('pay') }}</button>
    <button @click="backToApp" v-if="hasPayed">{{ $t('back_to_app') }}</button>

    <p v-if="isAdmin">Admin:</p>
    <button v-if="isAdmin" @click="createFreeAccount">Skapa gratis konto</button>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export default {
  name: 'account',
  data() {
    return {
      user: {},
      hasPayed: false,
      isAdmin: false,
    };
  },
  created() {
    console.log('created');
    const auth = getAuth();
    const firestore = getFirestore();
    this.user = auth.currentUser;

    if (this.$store.state.userID === '') {
      this.$router.push({ path: '/' });
      return;
    }

    getDoc(doc(firestore, 'users', this.$store.state.userID)).then((res) => {
      const data = res.data();
      if (data) {
        if (data.hasPayed) {
          this.hasPayed = data.hasPayed;
        }
      }
    });

    getDoc(doc(firestore, 'admins', this.$store.state.userID)).then((res) => {
      if (res.exists()) {
        this.isAdmin = true;
      }
    });
  },
  methods: {
    pay() {
      // this.$router.go('payment');
      this.$router.push({ path: '/payment' });
      // this.$router.push({ name: 'payment' });
    },
    backToApp() {
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'backToApp' }));
      }
      window.location = 'dropAStory://';
    },
    createFreeAccount() {
      this.$router.push({ path: '/createFreeAccount' });
    },
  },
};
</script>

<style lang="sass" scoped>
.create_account
  margin-top: 40px
  flex-direction: column
  display: flex
  align-items: center

  input
    margin: 10px 0
    width: 20%
    padding: 15px

  button
    margin-top: 20px
    width: 10%
    cursor: pointer
</style>
