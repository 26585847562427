<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <p>temp {{loaded}} {{$router.currentRoute.name}}</p> -->
    <router-view
    v-if="loaded"
    />
  </div>
</template>

<script>

import { getAuth } from 'firebase/auth';
import { mapState } from 'vuex';

export default {
  name: 'app',
  data: () => ({
    // loaded: false,
  }),
  created() {
    const auth = getAuth();
    if (auth.currentUser) {
      this.$store.dispatch('setUser', auth.currentUser);
      // this.loaded = true;
    }
    auth.onAuthStateChanged((user) => {
      // console.log('created', user);
      this.$store.dispatch('setUser', user);
      // this.loaded = true;
    });
    setTimeout(() => {
      // this.loaded = true;
      if (!this.$store.state.loaded) {
        this.$store.dispatch('setUser', null);
      }
    }, 2000);
  },
  computed: mapState({
    loaded: state => state.loaded,
  }),
};
</script>

<style lang="sass">

$break-small: 800px
$break-large: 1200px

body
  margin: 0
  padding: 0
  background-image: url("./assets/Login_background.png")
  background-size: 100%
  background-attachment: fixed
  background-color: black
  background-repeat: repeat

#app
  font-family: 'Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  padding: 0
  margin: 0

#nav
  padding: 30px

  a
    font-weight: bold
    color: #2c3e50

    &.router-link-exact-active
      color: #42b983

.form
  flex-direction: column
  display: flex
  align-items: center
  background-color: #ffffffee
  margin: 5% auto 15% auto
  width: 50%
  // min-width: 300px
  border-radius: 30px
  margin-top: 100px
  margin-bottom: 50px

  @media (max-width: $break-small)
    width: 90%

  .logo
    margin-top: -50px

  h1
    color: #4A3C69

  input
    margin: 10px 0
    width: 80%
    padding: 15px
    font-size: 16px

  button
    margin: 40px
    cursor: pointer
    padding: 20px
    background-color: #E94F58
    border: none
    color: white
    padding: 15px 62px
    text-align: center
    text-decoration: none
    display: inline-block
    font-size: 16px
    border-radius: 40px


    &:disabled
      cursor: default
      background-color: gray
      &:hover
        box-shadow: none

  a
    color: #E94F58
    text-decoration: none
    font-size: 1em
    font-weight: normal

a
  color: white
  text-decoration: none
  font-weight: bold
  font-size: 2em

// .lds-dual-ring
//   display: inline-block
//   width: 80px
//   height: 80px

//   &:after
//     content: " "
//     display: block
//     width: 64px
//     height: 64px
//     margin: 8px
//     border-radius: 50%
//     border: 6px solid #4A3C69
//     border-color: #4A3C69 transparent #4A3C69 transparent
//     animation: lds-dual-ring 1.2s linear infinite

// @keyframes lds-dual-ring
//   0%
//     transform: rotate(0deg)
//   100%
//     transform: rotate(360deg)
</style>
