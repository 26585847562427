<template>
  <div class="form">
    <img src="../assets/logo.png" class="logo" />
    <h1 v-if="paymentComplete">{{ $t('payment_complete') }}</h1>
    <h1 v-if="!paymentComplete">{{ $t('payment_finishing') }}</h1>
    <h3>{{ error }}</h3>
    <div v-if="!paymentComplete" class="lds-dual-ring"></div>
    <button v-if="paymentComplete" @click="backToApp">{{ $t('back_to_app') }}</button>
    <!-- <button @click="checkPaymentSatus">Kolla status</button> -->
    <!-- <p>{{status}}</p> -->
    <!-- <iframe v-html="htmlSnippet">

    </iframe>
    <iframe :src="htmlSnippet">

    </iframe> -->
    <!-- <vue-friendly-iframe :src="htmlSnippet"></vue-friendly-iframe> -->
    <!-- <div v-html="htmlSnippet">

    </div> -->
    <!-- <div id="my-checkout-container" style="width: 50%;"></div> -->
  </div>
</template>

<script>
import firebase from 'firebase/app';
// import axios from 'axios';
// import VueFriendlyIframe from 'vue-friendly-iframe';

export default {
  name: 'payment',
  components: {
    // VueFriendlyIframe,
  },
  data() {
    return {
      email: '',
      password: '',
      htmlSnippet: '',
      status: '',
      error: '',
      paymentComplete: true,
    };
  },
  computed: {
    orderId() {
      // console.log(this.$route.query);

      if (this.$route.query.klarna_order_id) {
        return this.$route.query.klarna_order_id;
      }
      return '';
    },
  },
  async mounted() {
    // this.checkPaymentSatus();
  },
  methods: {
    checkPaymentSatus() {
      // console.log('payment status: ', this.$store.state.userID, firebase.auth().currentUser);

      // console.log(this.$store.state);

      firebase
        .functions()
        .httpsCallable('checkPurchaseStatus')({ order_id: this.orderId })
        .then(async (res) => {
          // console.log(res);
          this.status = res.data.status;
          // this.renderSnippet(res.data);

          // if payment status is ok, show user and navigate to app
          if (this.status === 'AUTHORIZED') {
            const user = await firebase.auth().currentUser;

            if (user) {
              // const uid = await user.uid;
              // console.log('payment s: ', this.$store.state.userID, firebase.auth().currentUser);
              firebase
                .firestore()
                .collection('users')
                .doc(this.$store.state.userID)
                .set(
                  {
                    hasPayed: true,
                  },
                  { merge: true },
                )
                .then(() => {
                  this.paymentComplete = true;
                  // navigate to the app
                  // this.backToApp();
                });
            } else {
              // console.log('something went wrong');
            }
          } else {
            this.error = this.$t('payment_failed');
          }
        });
    },
    backToApp() {
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'backToApp' }));
      }
      window.location = 'dropAStory://';
    },
  },
};
</script>

<style lang="sass" scoped>
.sign_in
  margin-top: 40px
  flex-direction: column
  display: flex
  align-items: center

  input
    margin: 10px 0
    width: 20%
    padding: 15px

  button
    margin-top: 20px
    width: 10%
    cursor: pointer
</style>
