<template>
  <div class="form">
    <img src="../assets/logo.png" class="logo" />
    <h1>Skapa gratis konto. Endast admin kan göra detta.</h1>
    <input type="text" :placeholder="$t('name')" v-model="name" />
    <input type="text" :placeholder="$t('email')" v-model="email" />
    <input type="password" :placeholder="$t('password')" v-model="password" />
    <input type="password" :placeholder="$t('repeat_password')" v-model="repeatPassword" />
    <!-- <div v-if="loading" class="lds-dual-ring"></div> -->
    <DualRingLoader
      :loading="loading"
      color="#4A3C69"
      :size="40"
      style="margin-bottom: 30px"></DualRingLoader>
    <p class="error_message">{{ error }}</p>
    <button @click="createAccount">{{ $t('create_account') }}</button>
    <p v-if="created">Done!</p>

    <p>Om de redan har ett konto kan du sätta det som betalt här:</p>
    <input type="text" :placeholder="$t('email')" v-model="email" />
    <button @click="setPayed">Sätt som betalt</button>
    <p v-if="done">Done!</p>
  </div>
</template>

<script>
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DualRingLoader } from 'vue-spinners-css';

export default {
  name: 'create_account',
  data() {
    return {
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      uid: '',
      loading: false,
      error: '',
      done: false,
      created: false,
      firestore: undefined,
      functions: undefined,
    };
  },
  components: {
    DualRingLoader,
  },
  mounted() {
    // this.$route.query.test
    // console.log(this.$route.query);

    if (this.$route.query && this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    }
  },
  created() {
    if (this.$store.state.userID === '') {
      this.$router.push({ path: '/' });
    } else {
      this.firestore = getFirestore();
      this.functions = getFunctions();

      getDoc(doc(this.firestore, 'admins', this.$store.state.userID)).then((snap) => {
        if (!snap.exists) {
          this.$router.push({ path: '/' });
        }
      });
    }
  },
  methods: {
    createAccount() {
      this.created = false;
      this.error = '';
      if (this.loading) {
        return;
      }
      this.loading = true;

      if (this.password !== this.repeatPassword) {
        this.error = this.$t('create_error_missmatch');
        return;
      }
      if (this.name === '') {
        this.error = this.$t('create_error_missmatch');
        return;
      }

      httpsCallable(
        this.functions,
        'createFreeAccount',
      )({
        email: this.email,
        password: this.password,
        displayName: this.name,
      })
        .then(() => {
          this.created = true;
          this.loading = false;
        })
        .catch((e) => {
          this.error = `Could no create account. ${JSON.stringify(e)}`;
        });

      // this.loading = true;
      // firebase.auth()
      //   .createUserWithEmailAndPassword(this.email, this.password)
      //   .then((user) => {
      //     // commit changes to react native if present
      //     if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      //       window.ReactNativeWebView.postMessage(JSON.str
      // ingify({ type: 'password', password: this.password, email: this.email }));
      //     }
      //     user.user.updateProfile({
      //       displayName: this.name,
      //     }).then(() => {
      //       // navigate to payment
      //       this.$store.commit('setEmail', this.email);
      //       this.$store.commit('setPassword', this.password);
      //       // this.$router.push('payment');
      //     }).then(() => {
      //       firebase.firestore()
      //       this.loading = false;

      //     });
      //   }).catch((e) => {
      //     if (e && e.code === 'auth/email-already-in-use') {
      //       this.error = this.$t('create_error_email_in_use');
      //     } else if (e && e.code === 'auth/invalid-email') {
      //       this.error = this.$t('create_error_invalid_email');
      //     } else if (e && e.code === 'auth/weak-password') {
      //       this.error = this.$t('create_error_weak_password');
      //     }
      //     this.loading = false;
      //   });
    },
    setPayed() {
      this.done = false;
      this.error = '';
      this.loading = true;

      httpsCallable(
        this.functions,
        'setHasPayed',
      )({ email: this.email })
        .then(() => {
          this.done = true;
          this.loading = false;
        })
        .catch((e) => {
          this.error = `Could no create account. ${JSON.stringify(e)}`;
        });
    },
  },
};
</script>

<style lang="sass" scoped>

.error_message
  color: red

p
  padding: 0px 10px
</style>
