import Vue from 'vue';
import VueRouter from 'vue-router';
// import firebase from 'firebase';
import Signin from '../views/Signin.vue';
// import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'signin',
    component: Signin,
    // beforeEnter: (to, from, next) => {
    //   if (!store.userID) {
    //     next('account');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/create_account',
    name: 'create_account',
    component: () => import('../views/CreateAccount.vue'),
  },
  {
    path: '/account',
    name: 'account',
    // meta: { requiresAuth: true },
    component: () => import('../views/Account.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    // meta: { requiresAuth: true },
    component: () => import('../views/Payment.vue'),
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    // meta: { requiresAuth: true },
    component: () => import('../views/Confirmation.vue'),
  },
  {
    path: '/createFreeAccount',
    name: 'createFreeAccount',
    // meta: { requiresAuth: true },
    component: () => import('../views/createFreeAccount.vue'),
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/signout',
    name: 'signout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/signout.vue'),
  },
  {
    path: '/delete',
    name: 'delete',
    component: () => import('../views/DeleteAccount.vue'),
  },
  {
    // will match everything
    path: '*',
    redirect: { name: 'create_account' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // routes: [
  //   {
  //     // will match everything
  //     path: '*',
  //     component: () => import('../views/Building.vue'),
  //   },
  // ],
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     const isAuthenticated = firebase.auth().currentUser !== null;
//     if (!isAuthenticated) {
//       // next('/');
//       // return;
//     }
//   }
//   next();
// });

export default router;
