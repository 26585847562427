<template>
  <div>
    <div class="form">
      <img src="../assets/logo.png" class="logo" />
      <h1>{{ $t('signin') }}</h1>
      <input type="text" :placeholder="$t('email')" v-model="email" />
      <input type="password" :placeholder="$t('password')" v-model="password" />
      <router-link to="forgot_password">{{ $t('forgot_password') }}</router-link>
      <p class="error_message">{{ error }}</p>
      <button @click="signIn">{{ $t('signin') }}</button>
    </div>
    <router-link to="create_account">{{ $t('create_account') }}</router-link>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export default {
  name: 'create_account',
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  methods: {
    signIn() {
      // const credential = firebase.auth.EmailAuthProvider.credential(this.email, this.password);
      // console.log('credential', credential);
      const auth = getAuth();

      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ type: 'password', password: this.password, email: this.email }),
            );
          }
          if (this.$router.currentRoute.query.redirect) {
            this.$router.push(this.$router.currentRoute.query.redirect);
            return;
          }
          this.$router.push('account');

          // redirect to an account page or payment page
        })
        .catch(() => {
          this.error = this.$t('signin_fail');
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
