<template>
  <div class="form">
    <img src="../assets/logo.png" class="logo" />
    <h1>{{ $t('forgot_password') }}</h1>
    <p v-if="success">{{ $t('forgot_confirm') }}</p>
    <input type="text" :placeholder="$t('email')" v-model="email" />
    <p class="error" v-if="error !== ''">{{ error }}</p>
    <button @click="forgotPassword">{{ $t('forgot_password') }}</button>
  </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'create_account',
  data() {
    return {
      email: '',
      error: '',
      success: false,
    };
  },
  methods: {
    forgotPassword() {
      const actionCodeSettings = {
        url: 'https://account.dropastory.se/',
      };

      const auth = getAuth();

      sendPasswordResetEmail(auth, this.email, actionCodeSettings)
        .then(() => {
          // console.log('email sent');
          this.success = true;
          this.error = '';
        })
        .catch(() => {
          // console.log('email failed', e);
          this.success = false;
          this.error = this.$t('forgot_error');
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.sign_in
  margin-top: 40px
  flex-direction: column
  display: flex
  align-items: center

  input
    margin: 10px 0
    width: 20%
    padding: 15px

  button
    margin-top: 20px
    width: 10%
    cursor: pointer

.error
  color: red
</style>
