import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import VueI18n from 'vue-i18n';
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import * as VueSpinnersCss from 'vue-spinners-css';
import App from './App.vue';
import router from './router';
import store from './store';

import se from './locales/se';


Vue.use(VueSpinnersCss);


// Sentry.init({
//   dsn: 'https://2e9e7d2deaef47468b89527bc649c7c6@sentry.io/2209221',
//   integrations: [new Integrations.Vue({ Vue, attachProps: true })],
// });

Vue.use(VueI18n);

const firebaseConfig = {
  apiKey: 'AIzaSyAvItKiRrBjcWIoQsalrhsdFAVOeHSAD30',
  authDomain: 'drop-a-story-app.firebaseapp.com',
  databaseURL: 'https://drop-a-story-app.firebaseio.com',
  projectId: 'drop-a-story-app',
  storageBucket: 'drop-a-story-app.appspot.com',
  messagingSenderId: '914991285689',
  appId: '1:914991285689:web:aa9bd1929110ca1914a268',
  measurementId: 'G-GZC6GDLMSB',
};

// Sentry.captureException(new Error('Something broke'));

initializeApp(firebaseConfig);
// firebase.functions().useFunctionsEmulator('http://localhost:5000');

Vue.config.productionTip = false;

const messages = {
  en: {
    message: {
      hello: 'hello world',
    },
  },
  se,
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'se', // set locale
  messages, // set locale messages
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
