<template>
  <div class="form">
    <img src="../assets/logo.png" class="logo" />
    <h1>{{$t('pay_with_klarna')}}</h1>
    <p>{{$t('purchase_account')}}</p>
    <!-- <button @click="initiatePayment">Betala</button> -->

    <!-- <div v-if="loading" class="lds-dual-ring"></div> -->
    <!-- <DualRingLoader :loading="loading" color="#4A3C69"></DualRingLoader>
    <p v-if="loading">{{$t('laddar')}}</p> -->
    <!-- <div
      id="my-checkout-container"
      style="width: 90%; margin: 30px;"
    ></div> -->
    <!-- <div
      v-for="category in payment_method_categories"
      :key="category.identifier"
      class="klarna-category"
      @click="selectCategory(category)">
      <div class="payment-row">
        <img :src="category.asset_urls.standard"/>
        <p >{{category.name}}</p>
      </div>
      <div
      v-show="selectedCategory && selectedCategory.identifier === category.identifier"
      :id="`klarna-payments-container${category.identifier}`" class="payment-container" ></div>
    </div>

    <button v-if="selectedCategory" @click="purchase()" :disabled="processingPayment">Köp</button>
    <DualRingLoader :loading="processingPayment" color="#4A3C69" :size="40"></DualRingLoader> -->

    <!-- <h1>Please give us your payment details:</h1> -->
    <!-- <card class='stripe-card'
      :class='{ complete }'
      stripe='pk_test_r8REZWY4VZNNHJdou7DNWocW00yMcsyapd'
      :options='stripeOptions'
      @change='complete = $event.complete'
    /> -->
    <!-- <div ref="card" class="stripe-card"></div> -->
    <p v-if="errorMessage" class="error-message">{{errorMessage}}</p>
    <form id="payment-form" class="stripe-card">
  <!-- We'll put the error messages in this element -->
  <!-- <div id="card-errors" role="alert"></div> -->
  <div id="card-element" class="StripeElement">
    <!-- Elements will create input elements here -->

  </div>


  <button id="submit" @click="pay()" >{{$t('pay_100')}}</button>
</form>
  <DualRingLoader
  :loading="loading" color="#4A3C69" :size="40" style="margin-bottom: 30px"></DualRingLoader>
    <img src="../assets/powered_by_stripe@2x.png" style="max-width: 30%" />

  </div>
</template>

<script>

import { DualRingLoader } from 'vue-spinners-css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const stripe = window.Stripe('pk_live_2vaEZktHD3gUZSPhuawFeaAA00oHF9WUKd');
const elements = stripe.elements();
let card;

const style = {
  base: {
    color: '#32325d',
  },
};
export default {
  name: 'payment',
  components: {
    // VueFriendlyIframe,
    DualRingLoader,
    // Card,
  },
  data() {
    return {
      email: '',
      password: '',
      htmlSnippet: '',
      loading: false,
      payment_method_categories: [
      ],
      session: '',
      selectedCategory: null,
      processingPayment: false,
      errorMessage: '',
      complete: false,
      stripeOptions: {

      },
      client_secret: '',
    };
  },
  mounted() {
    this.initiatePayment();

    card = elements.create('card', { style });
    card.mount('#card-element');
    card.addEventListener('change', ({ error }) => {
      console.log(error);

      if (error) {
        this.errorMessage = error.message;
      } else {
        this.errorMessage = '';
      }
    });

    const form = document.getElementById('payment-form');

    form.addEventListener('submit', (ev) => {
      ev.preventDefault();
    });
  },
  methods: {
    async pay() {
      this.loading = true;
      console.log(this.client_secret);
      const auth = getAuth();

      stripe.confirmCardPayment(this.client_secret, {
        payment_method: {
          card,
          billing_details: {
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
          },
        },
        receipt_email: auth.currentUser.email,
      }).then((result) => {
        console.log(result);

        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          console.log(result.error.message);
          this.errorMessage = result.error.message;
          this.loading = false;
        } else if (result.paymentIntent.status === 'succeeded') {
          this.$router.push('confirmation');
          this.loading = false;
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
        this.loading = false;
      });

      // stripe.createToken(card).then((result) => {
      // // Access the token with result.token
      //   if (result.error) {
      //     this.hasCardErrors = true;
      //     this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
      //     return;
      //   }
      //   console.log(result.token);
      // });

      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      // createToken().then(data => console.log(data.token));
      // console.log(confirmCardPayment);


      // const result = await handleCardPayment(this.client_secret, {
      //   payment_method: {
      //     // card: elements.getElement(CardElement),
      //     billing_details: {
      //       name: 'Jenny Rosen',
      //     },
      //   },
      // });
      // console.log(result);
      // if (result.error) {
      //   console.error(result.error);
      // } else if (result.paymenIntent.status === 'succeeded') {
      //   console.log('payment complete');
      // }
    },
    initiatePayment() {
      const functions = getFunctions();
      // connectFunctionsEmulator(functions, 'localhost', 5000);

      httpsCallable(functions, 'initiateStripe')().then((res) => {
        console.log(res);
        this.client_secret = res.data.client_secret;

        // if (res.data === 'already payed') {
        //   this.loading = false;
        //   this.$router.push({ path: '/account' });
        //   return;
        // }

        // console.log(res.data);
        // console.log(res.data.payment_method_categories);
        // window.Klarna.Payments.init({
        //   client_token: res.data.client_token,
        // });

        // this.payment_method_categories = res.data.payment_method_categories;
        // this.loading = false;

        // console.log(this.payment_method_categories);

        // // Klarna.Payments.load({
        // //   container: '#klarna-payments-container',
        // //   payment_method_category: 'pay_now',
        // // }, (res) => {
        // //   console.debug(res);
        // // });
        // // if (res.data === 'already payed') {
        // //   this.loading = false;
        // //   this.$router.push({ path: '/account' });
        // //   return;
        // // }
        // // this.renderSnippet(res.data);
      });
    },
    selectCategory(category) {
      this.selectedCategory = category;
      window.Klarna.Payments.load({
        container: `#klarna-payments-container${category.identifier}`,
        payment_method_category: category.identifier,
      }, (res) => {
        console.debug(res);
      });
    },
    purchase() {
      console.log(this.selectedCategory);

      this.processingPayment = true;
      window.Klarna.Payments.authorize({
        payment_method_category: this.selectedCategory.identifier,
      }, {
        purchase_country: 'SE',
        purchase_currency: 'SEK',
        locale: 'sv-se',
        order_amount: 10000,
        order_tax_amount: 2000,
        order_lines: [
          {
            name: 'account',
            quantity: 1,
            unit_price: 10000,
            tax_rate: 2500,
            total_amount: 10000,
            total_tax_amount: 2000,
          },
        ],
        auto_finalize: true,
        // customer: {
        //   date_of_birth: '1970-01-01',
        //   gender: 'male',
        // },
      }, (res) => {
        console.debug(res);
        if (res.approved) {
          const functions = getFunctions();
          httpsCallable(functions, 'placePaymentsOrder')({ authorization_token: res.authorization_token }).then((data) => {
            console.log(data.data);
            if (data.data.fraud_status === 'ACCEPTED') {
              // navigate to confirmation.
              this.$router.push('confirmation');
            }
            this.processingPayment = false;
          });
        } else {
          this.processingPayment = false;
          this.errorMessage = 'Köpet misslyckades';
        }
      });
      // firebase.functions().httpsCallable('authenticatePaymentsSession')().then((res) => {
      //   console.log(res.data);
      // })
    },
    // renderSnippet(htmlSnippet) {
    //   this.loading = false;
    //   const checkoutContainer = document.getElementById('my-checkout-container');
    //   checkoutContainer.innerHTML = htmlSnippet;
    //   const scriptsTags = checkoutContainer.getElementsByTagName('script');
    //   // This is necessary otherwise the scripts tags are not going to be evaluated
    //   for (let i = 0; i < scriptsTags.length; i += 1) {
    //     const { parentNode } = scriptsTags[i];
    //     const newScriptTag = document.createElement('script');
    //     newScriptTag.type = 'text/javascript';
    //     newScriptTag.text = scriptsTags[i].text;
    //     parentNode.removeChild(scriptsTags[i]);
    //     parentNode.appendChild(newScriptTag);
    //   }
    // },
  },
};
</script>

<style lang="sass" scoped>
.form
  margin-top: 40px
  flex-direction: column
  display: flex
  align-items: center

  padding-bottom: 30px

  input
    margin: 10px 0
    width: 20%
    padding: 15px

  button
    margin-top: 20px
    // width: 10%
    cursor: pointer
    // display: flex
    // flex-direction: row
    *
      float: right

.klarna-category
  display: flex
  flex-direction: column
  padding: 10px
  background-color: #ffffffee
  width: 90%
  border-radius: 5px
  box-shadow: 5px 5px 10px -10px rgba(0,0,0,0.25)
  cursor: pointer
  border: 1px solid #00000022

  .payment-row
    display: flex
    flex-direction: row

    p
      margin-left: 10px
      color: rgb(54,128,219)
      font-weight: bold

.payment-container
  // background-color: #ffffffee
  // width: 90%
  // padding: 0px 10px
  // border-radius: 5px
  // box-shadow: 5px 5px 10px -10px rgba(0,0,0,0.25)
  // border: 1px solid #00000022
  // margin: 30px
  // margin-bottom: 30px

.stripe-card
  // display: flex
  // flex: 1
  // min-width: 50%
  width: 90%

.StripeElement
  background-color: #ffffff
  padding: 10px
  box-shadow: 2px 2px 8px 2px #ccc
  border-radius: 5px

.error-message
  color: red

</style>
